export const ENVIRONMENT = {
  local: {
    auth0: {
      domain: '',
      clientId: '',
      realm: '',
    },
    // endpoint: 'https://api-dev.screenz.no/graphql',
    // endpoint: 'http://34.240.239.159:3000/graphql',
    endpoint: 'https://api-dev.screenz.no/graphql',
    restEndPoint: 'https://api-dev.screenz.no',
    s3: {
      accessKeyId: 'AKIASMEQVXCPF4WPXHG6',
      secretAccessKey: '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',
      region: 'eu-west-1',
      bucketName: 'screenz-io-dev-uploads',
    },
    redirectURI: 'http://localhost:3000/',
  },
  dev: {
    // endpoint: 'https://api-dev.screenz.no/graphql',
    // endpoint: 'http://34.240.239.159:3000/graphql',
    endpoint: 'https://api-dev.screenz.no/graphql',
    restEndPoint: 'https://api-dev.screenz.no',
    s3: {
      accessKeyId: 'AKIASMEQVXCPF4WPXHG6',
      secretAccessKey: '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',
      region: 'eu-west-1',
      bucketName: 'screenz-io-dev-uploads',
    },
    redirectURI: 'https://cms-dev.screenz.no/',
  },
  staging: {
    endpoint: 'https://api-staging.screenz.no/graphql',
    restEndPoint: 'https://api-staging.screenz.no',
    s3: {
      accessKeyId: 'AKIASMEQVXCPF4WPXHG6',
      secretAccessKey: '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',
      region: 'eu-west-1',
      bucketName: 'screenz-io-staging-uploads',
    },
    redirectURI: 'https://cms-staging.screenz.no/',
  },
  production: {
    endpoint: 'https://api-prod.screenz.no/graphql',
    restEndPoint: 'https://api-prod.screenz.no',
    s3: {
      accessKeyId: 'AKIAXYKJVPEN2EYKZWIN',
      secretAccessKey: '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',
      region: 'eu-central-1',
      bucketName: 'screenz-io-production-uploads',
    },
    redirectURI: 'https://admin.screenz.no/',
  },
};

// export const API_ENDPOINT = endpoint;
// export const AWS_ACCESS_KEY_ID_ENV = accessKeyId;
// export const AWS_SECRET_ACCESS_KEY_ENV = secretAccessKey;
// export const REGION = region;
// export const BUCKET_NAME = bucketName;

const { endpoint, restEndPoint, s3, redirectURI } =
  ENVIRONMENT.production || {};
const { accessKeyId, secretAccessKey, region, bucketName } = s3 || {};

export {
  endpoint,
  restEndPoint,
  accessKeyId,
  secretAccessKey,
  region,
  bucketName,
  redirectURI,
};
